import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Typography,
} from '@mui/material';
import { FC, Fragment, useContext } from 'react';

import { ARCHETYPE_WITH_IMAGE } from '../../../constants/constants';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles } from '../../../styles/global/accordionStyles';
import PaymentContainer from './components/PaymentContainer';
import { COVERAGE_CONFIGURATION_TYPE_MANUAL } from './constants';
import { localStyles } from './styles';

type Props = {
  t: any;
  currentPlan: any;
  classes: any;
  handleDownload: any;
  goToQuote?: any;
  onePlanView?: boolean;
};

const PlanCardContent: FC<Props> = ({
  t,
  classes,
  currentPlan,
  handleDownload,
  onePlanView = false,
}) => {
  const accordionClasses = useStyles();
  const localClasses = localStyles();
  const { portalArchetypeId } = useContext(BusinessUnitParamsContext);

  return (
    <Fragment>
      <div
        className={
          onePlanView && portalArchetypeId === ARCHETYPE_WITH_IMAGE
            ? classes.contentContainerWithPadding
            : classes.contentContainer
        }
      >
        {onePlanView === false && (
          <PaymentContainer t={t} currentPlan={currentPlan} classes={classes} />
        )}

        <div
          className={
            onePlanView && portalArchetypeId === ARCHETYPE_WITH_IMAGE
              ? classes.paymentContainer
              : localClasses.paymentContainerEmpty
          }
        >
          <Typography className={classes.title}>
            {t('components.cardPlan.detail.description')}
          </Typography>
          <div className={classes.chips}>
            {currentPlan.categories &&
              currentPlan.categories.map((itemCategory: any, indexCategory: any) => (
                <Chip
                  classes={{ root: classes.chip }}
                  label={itemCategory.categoryName}
                  key={indexCategory}
                />
              ))}
          </div>
          <Typography
            className={localClasses.defaultRichText}
            dangerouslySetInnerHTML={{
              __html: currentPlan?.description,
            }}
          />

          <div className={classes.subTitleContainer}>
            <Typography className={classes.title}>
              {t('components.cardPlan.detail.coverages')}
            </Typography>
            <div className={classes.grow} />
            <Button variant="outlined" className={classes.button} onClick={handleDownload}>
              {t('components.cardPlan.detail.downloadPlanSheet')}
            </Button>
            <Button variant="outlined" className={classes.buttonMobile} onClick={handleDownload}>
              {t('components.cardPlan.detail.downloadSheet')}
            </Button>
          </div>

          <div className={classes.coverageContainer}>
            {currentPlan?.coverages &&
              currentPlan?.coverages.map((currentCoverage: any, index: any) => (
                <Box key={index}>
                  <Accordion className={accordionClasses.item}>
                    <AccordionSummary
                      className={accordionClasses.title}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography
                        className={accordionClasses.titleResponsive}
                        variant={'subtitle1'}
                      >
                        {currentPlan?.coverageTypeConfigurationId ===
                        COVERAGE_CONFIGURATION_TYPE_MANUAL
                          ? currentCoverage.coverageName
                          : currentCoverage.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 25 }}>
                      <div className={classes.coverage}>
                        <div className={classes.coverageDetailsContainer}>
                          <Typography className={accordionClasses.textBold} variant={'body1'}>
                            {currentPlan?.coverageTypeConfigurationId ===
                            COVERAGE_CONFIGURATION_TYPE_MANUAL
                              ? currentCoverage.serviceTitle
                              : t('components.cardPlan.detail.services')}
                          </Typography>

                          {currentPlan?.coverageTypeConfigurationId ===
                          COVERAGE_CONFIGURATION_TYPE_MANUAL ? (
                            <Typography
                              className={localClasses.defaultRichText}
                              dangerouslySetInnerHTML={{
                                __html: currentCoverage.serviceDescription,
                              }}
                            />
                          ) : (
                            <>
                              {currentCoverage.serviceCoverages &&
                              currentCoverage.serviceCoverages.length > 0 ? (
                                <ul>
                                  {currentCoverage.serviceCoverages.map(
                                    (currentService: any, indexService: any) => (
                                      <li
                                        className={classes.subTitle}
                                        key={`service-${index}-${indexService}`}
                                      >{`${currentService.serviceName}`}</li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <Typography
                                  className={accordionClasses.textDetail}
                                  variant={'body1'}
                                >
                                  {t('components.cardPlan.detail.services.empty')}
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                        <div className={classes.coverageDetailsContainer}>
                          <Typography className={accordionClasses.textBold} variant={'body1'}>
                            {currentPlan?.coverageTypeConfigurationId ===
                            COVERAGE_CONFIGURATION_TYPE_MANUAL
                              ? currentCoverage.maximumTitle
                              : t('components.cardPlan.detail.tops')}
                          </Typography>

                          {currentPlan?.coverageTypeConfigurationId ===
                          COVERAGE_CONFIGURATION_TYPE_MANUAL ? (
                            <Typography
                              className={localClasses.defaultRichText}
                              dangerouslySetInnerHTML={{
                                __html: currentCoverage.maximumDescription,
                              }}
                            />
                          ) : (
                            <>
                              {currentCoverage.maximumCoverages &&
                              currentCoverage.maximumCoverages.length > 0 ? (
                                <ul>
                                  {currentCoverage.maximumCoverages.map(
                                    (currentTope: any, indexMaximumCoverage: any) => (
                                      <li
                                        className={classes.subTitle}
                                        key={`service-${index}-${indexMaximumCoverage}`}
                                      >{`${currentTope.scope} ${currentTope.unitCode} ${currentTope.periodicityName}`}</li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <Typography
                                  className={accordionClasses.textDetail}
                                  variant={'body1'}
                                >
                                  {t('components.cardPlan.detail.tops.empty')}
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                        <div className={classes.coverageDetailsContainer}>
                          <Typography className={accordionClasses.textBold} variant={'body1'}>
                            {currentPlan?.coverageTypeConfigurationId ===
                            COVERAGE_CONFIGURATION_TYPE_MANUAL
                              ? currentCoverage.restrictionTitle
                              : t('components.cardPlan.detail.restrictions')}
                          </Typography>
                          {currentPlan?.coverageTypeConfigurationId ===
                          COVERAGE_CONFIGURATION_TYPE_MANUAL ? (
                            <Typography
                              className={localClasses.defaultRichText}
                              dangerouslySetInnerHTML={{
                                __html: currentCoverage.restrictionDescription,
                              }}
                            />
                          ) : (
                            <>
                              {currentCoverage.observations &&
                              currentCoverage.observations.length > 0 ? (
                                <ul>
                                  {currentCoverage.observations.map(
                                    (currentObservation: any, indexObservation: any) => (
                                      <li
                                        key={`service-${index}-${indexObservation}`}
                                        className={classes.subTitle}
                                      >{`${currentObservation.description}`}</li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <Typography
                                  className={accordionClasses.textDetail}
                                  variant={'body1'}
                                >
                                  {t('components.cardPlan.detail.restrictions.empty')}
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <br />
                </Box>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PlanCardContent;
