import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const localStyles = makeStyles((theme: Theme) => ({
  defaultRichText: {
    '& *': {
      fontSize: '14px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  },
  paymentContainerEmpty: {
    background: 'none !important',
    borderRadius: '15px !important',
    boxShadow: 'none !important',
    display: 'grid',
    gap: 15,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));
