import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getPlanById } from '../../../api/api';
import { HIRE_PLAN } from '../../../constants/routes';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCurrentPlanIdToShowDetailsAction } from '../../../redux/actions/planActions';
import { getPlanSelector } from '../../../redux/selectors';
import PlanCardContent from '../../Common/PlanCardContent';
import { useStyles } from './styles';

const CardPlanDetailsDialog: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const [currentPlan, setCurrentPlan] = useState<any>({});
  const url: string = contextUrl!;
  const { planToShowDetailsId } = useSelector(getPlanSelector);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseDialog = () => {
    setCurrentPlan({});
    dispatch(setCurrentPlanIdToShowDetailsAction());
  };

  const goToQuote = (event: SyntheticEvent) => {
    event.preventDefault();
    handleCloseDialog();
    history.push({ pathname: `${HIRE_PLAN}/${planToShowDetailsId}` });
  };

  useEffect(() => {
    const init = async () => {
      if (planToShowDetailsId) {
        try {
          const response = await getPlanById(url, planToShowDetailsId);
          if (response.plans) setCurrentPlan(response.plans);
        } catch (error) {
          console.error(error);
        }
      }
    };
    init();
  }, [planToShowDetailsId]);

  const isOpenDialog = () => {
    return currentPlan['id'] != undefined;
  };

  const handleDownload = () => {
    window.open(currentPlan.url);
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={isOpenDialog()}
      classes={{ paper: classes.dialogPaper }}
      fullScreen={fullScreen}
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <div className={classes.header}>
          <Typography className={classes.planName}> {currentPlan.name}</Typography>
        </div>
        <div className={classes.grow} />
        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <PlanCardContent
          t={t}
          currentPlan={currentPlan}
          classes={classes}
          handleDownload={handleDownload}
        />
      </DialogContent>

      <DialogActions className={classes.buttonContainer}>
        <Button className={classes.buttonSubmit} variant="contained" onClick={goToQuote}>
          {t('components.cardPlan.button.quote')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardPlanDetailsDialog;
