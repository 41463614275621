import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: '#FAFAFA !important',
    width: '358.33px',
    height: '475px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '15px !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '475px',
      marginBottom: 18,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '495px',
      marginBottom: 18,
    },
  },
  img: {
    objectPosition: 'center center',
    objectFit: 'contain',
    height: '152px',
  },
  column: {
    height: '25%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0px 16px',
  },
  name: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px !important',
    },
  },
  columnCategories: {
    height: '5%',
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    padding: '0px 16px',
  },
  categories: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.38) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '12px',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    width: 'auto',
    paddingRight: '4px',
  },
  categoriesMore: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.38) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '12px',
    width: '25px',
  },
  description: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'justify',
    padding: '0px 16px !important',
  },
  columnButton: {
    height: '15%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0px 16px',
    justifyContent: 'flex-start',
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '13px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '117px',
    height: '30px',
    borderRadius: '20px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  columnDetail: {
    height: '15%',
    display: 'flex',
    width: '100%',
    padding: '0px 16px',
    textAlign: 'end',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));
