import { Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkRut, useRut } from 'react-rut-formatter';

import CustomPhoneInput from '../../../../../components/atoms/CustomPhoneInput';
import InputText from '../../../../../components/atoms/InputText';
import InputTextController from '../../../../../components/atoms/InputTextController';
import SelectController from '../../../../../components/atoms/SelectController';
import {
  BeneficiaryColumnsArrayModel,
  BeneficiaryColumnsModel,
  CountriesArrayModel,
  CountryModel,
  GendersArrayModel,
  GendersModel,
  IdentificationTypesArrayModel,
  IdentificationTypesModel,
  RegionModel,
  RegionsArrayModel,
} from '../../../../../models/HirePlanModel';
import {
  FORM_ADDITIONAL_FIELD_1_ID,
  FORM_ADDITIONAL_FIELD_2_ID,
  FORM_ADDITIONAL_FIELD_3_ID,
  FORM_ADDITIONAL_FIELD_4_ID,
  FORM_ADDRESS_INPUT_ID,
  FORM_DATE_OF_BIRTH_INPUT_ID,
  FORM_EMAIL_INPUT_ID,
  FORM_GENDER_INPUT_ID,
  FORM_LASTNAME_INPUT_ID,
  FORM_MOBILE_MODEL_BRAND_ID,
  FORM_NAME_INPUT_ID,
  FORM_NATIONALITY_INPUT_ID,
  FORM_PHONE_INPUT_ID,
  FORM_RELATIONSHIP_INPUT_ID,
  FORM_TYPE_AND_ID_INPUT_ID,
  RELATIONSHIP_OPTIONS,
} from '../../../constants';
import { BeneficiaryList } from '../../../models/BeneficiaryInputs';
import { TitularInputs } from '../../../models/TitularInputs';
import AccordionItem from '../../Common/AccordionItem';
import { useStyles } from '../../Common/Styles/elementStyles';

type StepProps = {
  id: number;
  beneficiaryColumns: BeneficiaryColumnsArrayModel;
  gendersProp: GendersArrayModel;
  identificationTypesProp: IdentificationTypesArrayModel;
  countriesProp: CountriesArrayModel;
  regionsProp: RegionsArrayModel;
  control: Control<BeneficiaryList>;
  alert: boolean;
  watch: any;
  beneficiaryRemove: any;
  setValue: UseFormSetValue<BeneficiaryList>;
  watchTitular: UseFormWatch<TitularInputs>;
  getCommunesByRegionID: (id: number, businessUnitUUID: string | undefined) => Promise<any>;
  identificationChange: (value: string | number) => boolean;
  isIdentificationType: boolean | undefined;
  triggerBeneficiary: UseFormTrigger<BeneficiaryList>;
  totalBeneficaries: number;
  businessUnitUUID: string | undefined;
  validateRange: boolean;
  validateRangeBeneficiary: boolean;
  validateRangeMin: number;
  validateRangeMax: number;
};

const BeneficiaryFormElement = (props: StepProps) => {
  const {
    id,
    beneficiaryColumns,
    gendersProp,
    identificationTypesProp,
    control,
    alert,
    watch,
    setValue,
    beneficiaryRemove,
    watchTitular,
    countriesProp,
    regionsProp,
    getCommunesByRegionID,
    identificationChange,
    isIdentificationType,
    triggerBeneficiary,
    totalBeneficaries,
    businessUnitUUID,
    validateRange,
    validateRangeBeneficiary,
    validateRangeMin,
    validateRangeMax,
  } = props;

  const [idTypes, setIdTypes] = useState<Array<object>>([]);
  const [relationships, setRelationships] = useState<Array<object>>([]);
  const [genders, setGenders] = useState<Array<object>>([]);
  const [countries, setCountries] = useState<Array<object>>([]);
  const [cities, setCities] = useState<Array<object>>([]);
  const [communes, setCommunes] = useState<Array<object>>([]);
  const { rut, updateRut } = useRut();
  const classes = useStyles();
  const { t } = useTranslation();
  const currentCity = watch(`beneficiaries.${id}.city`);
  const isSameData = watch(`beneficiaries.${id}.isSameData`);
  const currentIdentificationType = watch(`beneficiaries.${id}.identificationType`);
  const currentIdentificationNumber = watch(`beneficiaries.${id}.identificationNumber`);
  const currentEmail = watch(`beneficiaries.${id}.email`);

  useEffect(() => {
    const init = async () => {
      try {
        if (currentCity !== '' && currentCity >= 0) {
          const response = await getCommunesByRegionID(currentCity, businessUnitUUID);
          const communesList = response.map(createElementList).sort(sortByLabel);
          const currentCommuneId = watch(`beneficiaries.${id}.commune`);
          const currentCommune = communesList.find(
            (commune: any) => commune.value === currentCommuneId
          );
          !isSameData &&
            setValue(`beneficiaries.${id}.commune`, currentCommune ? currentCommuneId : '');
          setCommunes(communesList);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [currentCity]);

  useEffect(() => {
    setIdTypes(identificationTypesProp.map(createElementListWithName));
    setRelationships(RELATIONSHIP_OPTIONS.map(createElementWithCodeList));
    setGenders(gendersProp.map(createElementList));
  }, [identificationTypesProp, gendersProp]);

  useEffect(() => {
    setCountries(countriesProp.map(createElementList).sort(sortByLabel));
    setCities(regionsProp.map(createElementList).sort(sortByLabel));
  }, [countriesProp, regionsProp]);

  useEffect(() => {
    if (
      !isSameData &&
      currentIdentificationType &&
      currentIdentificationType !== '' &&
      currentIdentificationNumber &&
      currentIdentificationNumber !== ''
    ) {
      triggerBeneficiary(`beneficiaries.${id}.identificationType`);
      triggerBeneficiary(`beneficiaries.${id}.identificationNumber`);
    }
    for (let index = 0; index < totalBeneficaries; index++) {
      if (index !== id) {
        triggerBeneficiary(`beneficiaries.${index}.identificationType`);
        triggerBeneficiary(`beneficiaries.${index}.identificationNumber`);
      }
    }
  }, [currentIdentificationType, currentIdentificationNumber]);

  useEffect(() => {
    for (let index = 0; index < totalBeneficaries; index++) {
      if (index !== id) {
        triggerBeneficiary(`beneficiaries.${index}.email`);
      }
    }
  }, [currentEmail]);

  const sortByLabel = (a: any, b: any) => (a.label > b.label ? 1 : -1);

  const createElementList = (item: CountryModel | RegionModel | GendersModel | any) => {
    return {
      label: item.name,
      value: item.id,
    };
  };

  const createElementWithCodeList = (item: IdentificationTypesModel | any) => {
    return {
      label: item.name,
      value: item.code,
    };
  };

  const createElementListWithName = (item: CountryModel | RegionModel | GendersModel | any) => {
    return {
      label: item.name,
      value: item.name,
    };
  };

  useEffect(() => {
    const index = beneficiaryColumns.findIndex(
      (element) => element.customerColumnId === FORM_TYPE_AND_ID_INPUT_ID
    );

    if (index !== -1) {
      const typeAndIDelement: BeneficiaryColumnsModel[] = beneficiaryColumns.splice(index, 1);

      beneficiaryColumns.splice(2, 0, typeAndIDelement[0]);
    }
  }, [beneficiaryColumns]);

  const handleRutFormat = () => {
    setValue(`beneficiaries.${id}.identificationNumber`, rut.raw);
  };

  const handleGetValue = (value: string) => {
    if (watch(`beneficiaries.${id}.identificationType`) === 'RUT') {
      updateRut(value);
      if (rut.raw !== watch(`beneficiaries.${id}.identificationNumber`))
        setValue(`beneficiaries.${id}.identificationNumber`, rut.raw);
      return rut.raw;
    } else {
      return value;
    }
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    beneficiaryRemove(id);
  };

  const check = (e: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    if (e.target.checked) {
      setValue(`beneficiaries.${id}.name`, watchTitular('name'));
      setValue(`beneficiaries.${id}.lastName`, watchTitular('lastName'));
      setValue(`beneficiaries.${id}.identificationType`, watchTitular('identificationType'));
      setValue(`beneficiaries.${id}.identificationNumber`, watchTitular('identificationNumber'));
      setValue(`beneficiaries.${id}.phoneNumber`, watchTitular('phoneNumber'));
      setValue(`beneficiaries.${id}.email`, watchTitular('email'));
      setValue(`beneficiaries.${id}.relationship`, watchTitular('relationship'));
      setValue(`beneficiaries.${id}.nationality`, watchTitular('nationality'));
      setValue(`beneficiaries.${id}.gender`, watchTitular('gender'));
      setValue(`beneficiaries.${id}.birthday`, watchTitular('birthday'));
      setValue(`beneficiaries.${id}.city`, watchTitular('city'));
      setValue(`beneficiaries.${id}.commune`, watchTitular('commune'));
      setValue(`beneficiaries.${id}.address`, watchTitular('address'));
      setValue(`beneficiaries.${id}.number`, watchTitular('number'));
      setValue(`beneficiaries.${id}.mobileBrand`, watchTitular('mobileBrand'));
      setValue(`beneficiaries.${id}.mobileModel`, watchTitular('mobileModel'));
      setValue(`beneficiaries.${id}.additionalField1`, watchTitular('additionalField1'));
      setValue(`beneficiaries.${id}.additionalField2`, watchTitular('additionalField2'));
      setValue(`beneficiaries.${id}.additionalField3`, watchTitular('additionalField3'));
      setValue(`beneficiaries.${id}.additionalField4`, watchTitular('additionalField4'));
      if (watchTitular(`identificationType`) === 'RUT')
        updateRut(watchTitular('identificationNumber'));
    } else {
      setValue(`beneficiaries.${id}.name`, '');
      setValue(`beneficiaries.${id}.lastName`, '');
      setValue(`beneficiaries.${id}.identificationType`, '');
      setValue(`beneficiaries.${id}.identificationNumber`, '');
      setValue(`beneficiaries.${id}.phoneNumber`, '');
      setValue(`beneficiaries.${id}.email`, '');
      setValue(`beneficiaries.${id}.relationship`, '');
      setValue(`beneficiaries.${id}.nationality`, '');
      setValue(`beneficiaries.${id}.gender`, '');
      setValue(`beneficiaries.${id}.birthday`, '');
      setValue(`beneficiaries.${id}.city`, '');
      setValue(`beneficiaries.${id}.commune`, '');
      setValue(`beneficiaries.${id}.address`, '');
      setValue(`beneficiaries.${id}.number`, '');
      setValue(`beneficiaries.${id}.mobileBrand`, '');
      setValue(`beneficiaries.${id}.mobileModel`, '');
      setValue(`beneficiaries.${id}.additionalField1`, '');
      setValue(`beneficiaries.${id}.additionalField2`, '');
      setValue(`beneficiaries.${id}.additionalField3`, '');
      setValue(`beneficiaries.${id}.additionalField4`, '');
    }
    for (let index = 1; index < totalBeneficaries; index++) {
      triggerBeneficiary(`beneficiaries.${index}.email`);
      triggerBeneficiary(`beneficiaries.${index}.identificationType`);
      triggerBeneficiary(`beneficiaries.${index}.identificationNumber`);
    }
    return onChange(e.target.checked);
  };

  return (
    <AccordionItem
      id={id}
      title={t('pages.hirePlan.beneficiary.title', { id: id + 1 })}
      alert={alert}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {id === 0 && (
            <FormControlLabel
              control={
                <Controller
                  name={`beneficiaries.${id}.isSameData`}
                  control={control}
                  render={(controllerProps) => (
                    <Checkbox
                      value={controllerProps.field.value}
                      onChange={(e) => check(e, controllerProps.field.onChange)}
                      color="primary"
                      className={classes.checkboxStyle}
                      checked={isSameData}
                    />
                  )}
                />
              }
              label={
                <Typography className={classes.label}>
                  {t('pages.hirePlan.beneficiary.checkbox.label')}
                </Typography>
              }
            />
          )}
        </Grid>
        {beneficiaryColumns.map((column: BeneficiaryColumnsModel) => (
          <React.Fragment key={`beneficiary-${id}-${column.customerColumnId}`}>
            {column.customerColumnId === FORM_NAME_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.name`}
                  label={t('pages.hirePlan.titular.form.name')}
                  placeholder={t('pages.hirePlan.titular.form.name')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                  disabled={isSameData && !!watchTitular('name')}
                  onlyLetters
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_LASTNAME_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.lastName`}
                  label={t('pages.hirePlan.titular.form.lastName')}
                  placeholder={t('pages.hirePlan.titular.form.lastName')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                  disabled={isSameData && !!watchTitular('lastName')}
                  onlyLetters
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_TYPE_AND_ID_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <SelectController
                  id={id}
                  name={`beneficiaries.${id}.identificationType`}
                  control={control}
                  data={idTypes}
                  label={t('pages.hirePlan.titular.form.identificationType')}
                  className={classes.formSelect}
                  required={column.required}
                  disabled={isSameData && !!watchTitular('identificationType')}
                  checkSameValue={isIdentificationType ? identificationChange : undefined}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_TYPE_AND_ID_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`beneficiaries.${id}.identificationNumber`}
                  control={control}
                  rules={{
                    required: {
                      message: `${t(
                        'pages.hirePlan.titular.form.identificationNumber'
                      )} es requerido.`,
                      value: column.required,
                    },
                    validate: {
                      rutValidator: (identificationNumber) => {
                        if (watch(`beneficiaries.${id}.identificationType`) === 'RUT') {
                          return checkRut(identificationNumber) || 'RUT no valido';
                        } else {
                          return true;
                        }
                      },
                      sameValue: () => {
                        if (isIdentificationType && identificationChange) {
                          return !identificationChange(id);
                        } else {
                          return true;
                        }
                      },
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <InputText
                      value={handleGetValue(value)}
                      label={t('pages.hirePlan.titular.form.identificationNumber')}
                      placeholder={t('pages.hirePlan.titular.form.identificationNumber')}
                      variant="outlined"
                      minLength={1}
                      fullWidth
                      required={column.required}
                      disabled={isSameData && !!watchTitular('identificationNumber')}
                      className={classes.formInput}
                      onChange={(element) => {
                        if (watch(`beneficiaries.${id}.identificationType`) === 'RUT') {
                          updateRut(element.target.value);
                          handleRutFormat();
                        }
                        onChange(element);
                      }}
                      error={error}
                    />
                  )}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_NATIONALITY_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <SelectController
                  name={`beneficiaries.${id}.nationality`}
                  control={control}
                  data={countries}
                  label={t('pages.hirePlan.titular.form.nacionality')}
                  className={classes.formSelect}
                  required={column.required}
                  disabled={isSameData && !!watchTitular('nationality')}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_RELATIONSHIP_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <SelectController
                  name={`beneficiaries.${id}.relationship`}
                  control={control}
                  data={relationships}
                  label={t('pages.hirePlan.titular.form.relationship')}
                  className={classes.formSelect}
                  required={column.required}
                  disabled={isSameData && !!watchTitular('relationship')}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_GENDER_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <SelectController
                  name={`beneficiaries.${id}.gender`}
                  control={control}
                  data={genders}
                  label={t('pages.hirePlan.titular.form.gender')}
                  className={classes.formSelect}
                  required={column.required}
                  disabled={isSameData && !!watchTitular('gender')}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_DATE_OF_BIRTH_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.birthday`}
                  label={t('pages.hirePlan.titular.form.birthday')}
                  type={'date'}
                  placeholder={t('pages.hirePlan.titular.form.birthday')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                  disabled={isSameData && !!watchTitular('birthday')}
                  typeInternal={'birthday'}
                  validateRange={validateRange}
                  validateRangeInternal={validateRangeBeneficiary}
                  validateRangeMin={validateRangeMin}
                  validateRangeMax={validateRangeMax}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_EMAIL_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.email`}
                  label={t('pages.hirePlan.titular.form.email')}
                  placeholder={t('pages.hirePlan.titular.form.email')}
                  type={'email'}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                  disabled={isSameData && !!watchTitular('email')}
                  checkSameValue={isIdentificationType ? undefined : identificationChange}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_PHONE_INPUT_ID && (
              <Grid item xs={12} sm={6}>
                <CustomPhoneInput
                  name={`beneficiaries.${id}.phoneNumber`}
                  control={control}
                  label={t('pages.hirePlan.titular.form.phoneNumber')}
                  required={column.required}
                  disabled={isSameData && !!watchTitular('phoneNumber')}
                  variant={'outlined'}
                  id={'phoneNumber'}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_ADDRESS_INPUT_ID && (
              <React.Fragment key={`beneficiary-${id}-${column.customerColumnId}-address-input`}>
                <Grid item xs={12} sm={6}>
                  <SelectController
                    name={`beneficiaries.${id}.city`}
                    control={control}
                    data={cities}
                    label={t('pages.hirePlan.titular.form.city')}
                    className={classes.formSelect}
                    required={column.required}
                    disabled={isSameData && !!watchTitular('city')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectController
                    name={`beneficiaries.${id}.commune`}
                    control={control}
                    data={communes}
                    label={t('pages.hirePlan.titular.form.commune')}
                    className={classes.formSelect}
                    required={column.required}
                    disabled={communes.length === 0 || (isSameData && !!watchTitular('commune'))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputTextController
                    name={`beneficiaries.${id}.address`}
                    label={t('pages.hirePlan.titular.form.address')}
                    placeholder={t('pages.hirePlan.titular.form.address')}
                    control={control}
                    required={column.required}
                    minLength={1}
                    className={classes.formInput}
                    disabled={isSameData && !!watchTitular('address')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputTextController
                    name={`beneficiaries.${id}.number`}
                    label={t('pages.hirePlan.titular.form.number')}
                    type={'number'}
                    placeholder={t('pages.hirePlan.titular.form.number')}
                    control={control}
                    required={column.required}
                    minLength={1}
                    className={classes.formInput}
                    disabled={isSameData && !!watchTitular('number')}
                  />
                </Grid>
              </React.Fragment>
            )}
            {column.customerColumnId === FORM_MOBILE_MODEL_BRAND_ID && (
              <React.Fragment key={`beneficiary-${id}-${column.customerColumnId}-mobile-input`}>
                <Grid item xs={12} sm={6}>
                  <InputTextController
                    name={`beneficiaries.${id}.mobileBrand`}
                    label={t('pages.hirePlan.titular.form.mobileBrand.label')}
                    placeholder={t('pages.hirePlan.titular.form.mobileBrand.placeholder')}
                    control={control}
                    required={column.required}
                    minLength={1}
                    disabled={isSameData && !!watchTitular('mobileBrand')}
                    className={classes.formInput}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputTextController
                    name={`beneficiaries.${id}.mobileModel`}
                    label={t('pages.hirePlan.titular.form.mobileModel.label')}
                    placeholder={t('pages.hirePlan.titular.form.mobileModel.placeholder')}
                    control={control}
                    required={column.required}
                    minLength={1}
                    disabled={isSameData && !!watchTitular('mobileModel')}
                    className={classes.formInput}
                  />
                </Grid>
              </React.Fragment>
            )}
            {column.customerColumnId === FORM_ADDITIONAL_FIELD_1_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.additionalField1`}
                  label={t('pages.hirePlan.titular.form.additionalField1.label')}
                  placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  disabled={isSameData && !!watchTitular('additionalField1')}
                  className={classes.formInput}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_ADDITIONAL_FIELD_2_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.additionalField2`}
                  label={t('pages.hirePlan.titular.form.additionalField2.label')}
                  placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  disabled={isSameData && !!watchTitular('additionalField2')}
                  className={classes.formInput}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_ADDITIONAL_FIELD_3_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.additionalField3`}
                  label={t('pages.hirePlan.titular.form.additionalField3.label')}
                  placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  disabled={isSameData && !!watchTitular('additionalField3')}
                  className={classes.formInput}
                />
              </Grid>
            )}
            {column.customerColumnId === FORM_ADDITIONAL_FIELD_4_ID && (
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={`beneficiaries.${id}.additionalField4`}
                  label={t('pages.hirePlan.titular.form.additionalField4.label')}
                  placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  disabled={isSameData && !!watchTitular('additionalField4')}
                  className={classes.formInput}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={12} className={classes.buttonContainer}>
          {id !== 0 && (
            <Link href="#" onClick={handleDelete} className={classes.link} underline="hover">
              {t('pages.hirePlan.beneficiary.button.delete')}
            </Link>
          )}
        </Grid>
      </Grid>
    </AccordionItem>
  );
};

export default BeneficiaryFormElement;
