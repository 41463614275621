import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Card, CardActions, CardContent, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  createPayrollSubscription,
  createPlanSold,
  createPlanSoldAuthorizeRenewal,
  getCommunesByRegionID,
  getInformationForContractingPlan,
  updatePlanSold,
} from '../../api/api';
import CardPlanDetailsDialog from '../../components/CardPlan/CardPlanDetailsDialog';
import CustomBreadcumbs from '../../components/CustomBreadcumbs';
import ContainerPage from '../../components/Layout/ContainerPage';
import HorizontalLinearStepper from '../../components/Stepper';
import { HIRE_PLAN, HOME } from '../../constants/routes';
import { HIRE_PLAN_VIEW_NAME } from '../../constants/views';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { HirePlanModel } from '../../models/HirePlanModel';
import { setCurrentViewLink, setCurrentViewName } from '../../redux/actions/appActions';
import { getDataPrivacyTermsSelector } from '../../redux/selectors/index';
import BeneficiaryFormStep from './components/BeneficiaryFormStep';
import CardHirePlanMobileWithArcheType from './components/CardHirePlanMobileWithArcheType';
import CardHirePlanWithArcheType from './components/CardHirePlanWithArcheType';
import ErrorScreen from './components/ErrorScreen';
import InsuranceLifeFormStep from './components/InsuranceLifeFormStep';
import MultiPaymentModal from './components/MultiPaymentModal';
import ResumeAndPaymentStep from './components/ResumeAndPaymentStep';
import TitularFormStep from './components/TitularFormStep';
import {
  BENEFICIARY_DATA_STEP,
  FORM_TYPE_AND_ID_INPUT_ID,
  INSURANCE_LIFE_DATA_STEP,
  RESUME_AND_PAYMENT_STEP,
  RESUME_AND_PAYMENT_STEP_2,
  TITULAR_DATA_STEP,
} from './constants';
import { BeneficiaryList } from './models/BeneficiaryInputs';
import { LifeInsuranceBeneficiaryList } from './models/LifeInsuranceBeneficiaryInputs';
import { TitularInputs } from './models/TitularInputs';
import { useStyles } from './styles';

interface RouteParams {
  id: string;
}

const HirePlan = () => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const planId = Number(params.id);
  const { contextUrl, businesUnitUUID, logo_url, name } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl ? contextUrl : '';
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const { dataPrivacyTerms } = useSelector(getDataPrivacyTermsSelector);

  const [activeStep, setActiveStep] = useState(0);
  const [infoToContract, setInfoToContract] = useState<HirePlanModel>();
  const [payload, setPayload] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertIdentification, setAlertIdentification] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [percentageFill, setPercentageFill] = useState(false);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [showPercentageAlert, setShowPercentageAlert] = useState(false);
  const [confirmInfoPlan, setConfirmInfoPlan] = useState(false);
  const [authorizeRenewal, setAuthorizeRenewal] = useState(true);
  const [planSoldUUID, setPlanSoldUUID] = useState('');
  const [payrollEndWizard, setPayrollEndWizard] = useState(false);
  const [multiPaymentModal, setMultiPaymentModal] = useState(false);
  const [paymentId, setPaymentId] = useState(0);
  const [beneficiaryList, setBeneficiaryList] = useState<BeneficiaryList>();
  const [lifeInsuranceBeneficiaryList, setLifeInsuranceBeneficiaryList] =
    useState<LifeInsuranceBeneficiaryList>();
  const [notPayrollEndWizard, setNotPayrollEndWizard] = useState(0);
  const [paymentUrl, setPaymentUrl] = useState('');
  const isPayroll = infoToContract?.infoPlan?.paymentTypeId === 1;
  const [errorScreen, setErrorScreen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string | null>(
    t('pages.hirePlan.errorScreen.title')
  );
  const [errorBody, setErrorBody] = useState<string | null>(t('pages.hirePlan.errorScreen.body'));

  const identificaitonType = infoToContract?.customerColumns.beneficiaryColumns.filter(
    (element) => element.customerColumnId === FORM_TYPE_AND_ID_INPUT_ID
  );

  const validateRange = infoToContract?.infoPlan?.ageValidationRequired;
  const validateRangeHeadline =
    infoToContract?.infoPlan?.customerAgeValidationId === 1 ||
    infoToContract?.infoPlan?.customerAgeValidationId === 3
      ? true
      : false;
  const validateRangeBeneficiary =
    infoToContract?.infoPlan?.customerAgeValidationId === 2 ||
    infoToContract?.infoPlan?.customerAgeValidationId === 3
      ? true
      : false;
  const validateRangeMin = infoToContract?.infoPlan?.numberMinimumAge;
  const validateRangeMax = infoToContract?.infoPlan?.numberMaximumAge;

  const isIdentificationType = identificaitonType && identificaitonType.length > 0;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors: errorsHeadline },
  } = useForm<TitularInputs>({
    mode: 'all',
  });

  const {
    control: controlBeneficiary,
    watch: watchBeneficiary,
    setValue: setValueBeneficiary,
    trigger: triggerBeneficiary,
    handleSubmit: handleSubmitBeneficiary,
    formState: { errors: errorsBeneficiary },
  } = useForm<BeneficiaryList>({
    mode: 'all',
  });

  const { fields, append, remove } = useFieldArray({
    control: controlBeneficiary,
    name: 'beneficiaries' as never,
  });

  const {
    control: controlInsuranceLifeBeneficiary,
    watch: watchInsuranceLifeBeneficiary,
    setValue: setValueInsuranceLifeBeneficiary,
    handleSubmit: handleInsuranceLifeBeneficiary,
  } = useForm<LifeInsuranceBeneficiaryList>({
    mode: 'all',
  });

  const {
    fields: fieldsInsuranceLifeBeneficiary,
    append: appendInsuranceLifeBeneficiary,
    remove: removeInsuranceLifeBeneficiary,
  } = useFieldArray({
    control: controlInsuranceLifeBeneficiary,
    name: 'lifeInsuranceBeneficiaries' as never,
  });

  useEffect(() => {
    dispatch(setCurrentViewName({ currentViewName: HIRE_PLAN_VIEW_NAME }));
    dispatch(setCurrentViewLink({ currentViewLink: HIRE_PLAN }));
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getInformationForContractingPlan(url, planId);
        setInfoToContract(response);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    infoToContract?.infoPlan.paymentsFrequencies &&
      infoToContract?.infoPlan.paymentsFrequencies.length > 0 &&
      setPaymentId(infoToContract?.infoPlan.paymentsFrequencies.length - 1);
  }, [infoToContract]);

  const percentageChange = () => {
    const total = 100;
    let percentageTotal = 0;
    let show = false;
    fieldsInsuranceLifeBeneficiary.forEach(
      (
        currentLifeInsuranceBeneficiary: Record<'id', string>,
        indexLifeInsuranceBeneficiary: number
      ) => {
        const currentValue = +watchInsuranceLifeBeneficiary(
          `lifeInsuranceBeneficiaries.${indexLifeInsuranceBeneficiary}.percentage`
        )?.replace(',', '.');
        show =
          show ||
          !!+watchInsuranceLifeBeneficiary(
            `lifeInsuranceBeneficiaries.${indexLifeInsuranceBeneficiary}.percentage`
          );
        percentageTotal += currentValue;
      }
    );

    setPercentageFill(percentageTotal !== total);
    setShowPercentageAlert(show);
  };

  const identificationChange = (value: string | number) => {
    if (isIdentificationType) {
      if (typeof value === 'string') {
        setAlertIdentification(false);
        return false;
      }

      const identification = fields.filter(
        (_, indexBeneficiary: number) =>
          indexBeneficiary !== value &&
          watchBeneficiary(`beneficiaries.${indexBeneficiary}.identificationType`) !== '' &&
          watchBeneficiary(`beneficiaries.${indexBeneficiary}.identificationNumber`) !== '' &&
          watchBeneficiary(`beneficiaries.${value}.identificationType`) ===
            watchBeneficiary(`beneficiaries.${indexBeneficiary}.identificationType`) &&
          watchBeneficiary(`beneficiaries.${value}.identificationNumber`) ===
            watchBeneficiary(`beneficiaries.${indexBeneficiary}.identificationNumber`)
      );

      setAlertIdentification(identification.length > 0);
      return identification.length > 0;
    } else {
      const email = fields.filter(
        (_, indexBeneficiary: number) =>
          value !== '' &&
          typeof value === 'string' &&
          watchBeneficiary(`beneficiaries.${indexBeneficiary}.email`) !== '' &&
          watchBeneficiary(`beneficiaries.${indexBeneficiary}.email`).trim() === value.trim()
      );

      setAlertEmail(email.length > 1);
      return email.length > 1;
    }
  };

  const getStepsToShow = () => {
    const steps = [
      {
        id: TITULAR_DATA_STEP,
        text: t('pages.hirePlan.steps.titular.label'),
        optional: false,
        visible: true,
      },
      {
        id: BENEFICIARY_DATA_STEP,
        text: t('pages.hirePlan.steps.beneficiary.label'),
        optional: false,
        visible: true,
      },
      {
        id: INSURANCE_LIFE_DATA_STEP,
        text: t('pages.hirePlan.steps.insuranceLife.label'),
        optional: false,
        visible: infoToContract?.infoPlan ? infoToContract.infoPlan.activeLifeInsurance : false,
      },
      {
        id: RESUME_AND_PAYMENT_STEP,
        text: isPayroll
          ? t('pages.hirePlan.steps.resumeAndPayment.payroll.label')
          : t('pages.hirePlan.steps.resumeAndPayment.label'),
        optional: false,
        visible: true,
      },
    ];

    return steps.filter((step) => step.visible === true);
  };

  const handleOpenDialogBeneficiaryList = (values: BeneficiaryList) => {
    setBeneficiaryList(values);
    setMultiPaymentModal(true);
  };

  const handleOpenDialogLifeInsuranceBeneficiaryList = (values: LifeInsuranceBeneficiaryList) => {
    setLifeInsuranceBeneficiaryList(values);
    setMultiPaymentModal(true);
  };

  const handleCloseDialog = () => {
    setMultiPaymentModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setConfirmInfoPlan(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    history.push({ pathname: HOME });
  };

  const handleDownload = () => {
    window.open(infoToContract?.infoPlan.urlPlan);
  };

  const handleOpen = () => {
    window.open(paymentUrl);
  };

  const handleGoLink = () => {
    if (infoToContract?.infoPlan.paymentTypeId === 3) {
      createPlanSoldAuthorizeRenewal(planSoldUUID, authorizeRenewal);
    }

    handleOpen();
    setNotPayrollEndWizard(2);
  };

  const onSubmit = (values: TitularInputs) => {
    setPayload((prevValues: any) => ({
      ...prevValues,
      ...values,
    }));
    handleNext();
    setAlert(false);
  };

  const onError = () => {
    setAlert(true);
  };

  const getPhoneFormat = (phone: any) => {
    return phone.replace(/ /g, '').replace('(', '').replace(')', '');
  };

  const getPrice = () => {
    if (infoToContract?.infoPlan.paymentsFrequencies) {
      if (infoToContract?.infoPlan.paymentsFrequencies.length > 1) {
        return infoToContract?.infoPlan.paymentsFrequencies[paymentId].price;
      } else {
        return infoToContract?.infoPlan.paymentsFrequencies[0].price;
      }
    } else {
      return infoToContract?.infoPlan.price;
    }
  };

  const getFrequencyId = () => {
    if (infoToContract?.infoPlan.paymentsFrequencies) {
      if (infoToContract?.infoPlan.paymentsFrequencies.length > 1) {
        return infoToContract?.infoPlan.paymentsFrequencies[paymentId].paymentFrequencyId;
      } else {
        return infoToContract?.infoPlan.paymentsFrequencies[0].paymentFrequencyId;
      }
    } else {
      return null;
    }
  };

  const getUrlByCreate = async (currentPayload: any) => {
    const body = {
      planId,
      ownerFullName: `${watch('name')} ${watch('lastName')}`,
      businessUnitUUID: businesUnitUUID,
      ownerEmail: currentPayload.email,
      ownerPhone: getPhoneFormat(currentPayload.phoneNumber),
      ownerAdress: currentPayload.address,
      ownerDocumentNumber: currentPayload.identificationNumber,
      ownerDocumentType: currentPayload.identificationType,
      unitLogo: logo_url,
      unitName: name,
      payment: getPrice(),
      paymentFrequencyId: getFrequencyId(),
    };
    try {
      const response = await createPlanSold(body);
      setPaymentUrl(response.url);
      setErrorTitle(t('pages.contact.result.error.title'));
      setErrorBody(null);
      await updatePlanSold(getBody(currentPayload), response.planSoldUUID);
      setPlanSoldUUID(response.planSoldUUID);

      handleCloseDialog();
      handleNext();
      setShowLinkButton(true);
    } catch (error) {
      setErrorScreen(true);
    }
  };

  const onSubmitBeneficiary = async (values: BeneficiaryList) => {
    const currentPayload = {
      ...payload,
      ...values,
    };
    setPayload(currentPayload);

    if (!isPayroll && !infoToContract?.infoPlan?.activeLifeInsurance) {
      await getUrlByCreate(currentPayload);
      setNotPayrollEndWizard(1);
    }

    handleNext();
    setAlert(false);
  };

  const onSubmitInsuranceLifeBeneficiary = async (values: LifeInsuranceBeneficiaryList) => {
    const currentPayload = {
      ...payload,
      ...values,
    };
    setPayload(currentPayload);
    if (!isPayroll && infoToContract?.infoPlan?.activeLifeInsurance) {
      setNotPayrollEndWizard(1);
      await getUrlByCreate(currentPayload);
    }

    handleNext();
    setAlert(false);
  };

  const onSubmitResumeAndPaymet = () => {
    createPayrollSubscription(getBody(payload), businesUnitUUID)
      .then(() => {
        handleNext();
        setPayrollEndWizard(true);
      })
      .catch((e) => {
        setErrorTitle(t('pages.contact.result.error.title'));
        setErrorBody(null);
        setErrorScreen(true);
        console.error(e);
      });
  };

  const onSubmitMultiPayment = async () => {
    const step = activeStep + 1;
    if (step === BENEFICIARY_DATA_STEP && beneficiaryList) {
      onSubmitBeneficiary(beneficiaryList);
    }

    if (step === INSURANCE_LIFE_DATA_STEP && lifeInsuranceBeneficiaryList) {
      onSubmitInsuranceLifeBeneficiary(lifeInsuranceBeneficiaryList);
    }
  };

  const getBody = (currentPayload: any) => {
    let beneficiaries = [];
    let lifeInsuranceBeneficiaries = [];
    let genderName: string | null = null;
    let countryName: string | null = null;

    if (infoToContract && infoToContract.genders) {
      const gender = infoToContract.genders.find((element) => element.id === currentPayload.gender);
      genderName = gender ? gender.name : null;
    }

    if (infoToContract && infoToContract.countries) {
      const country = infoToContract.countries.find(
        (element) => element.id === currentPayload.nationality
      );
      countryName = country ? country.name : null;
    }

    if (currentPayload.beneficiaries) {
      beneficiaries = getBeneficiariesPayload(currentPayload);
    }

    if (currentPayload.lifeInsuranceBeneficiaries) {
      lifeInsuranceBeneficiaries = currentPayload.lifeInsuranceBeneficiaries.map(
        (lifeInsuranceBeneficiary: any) => {
          return {
            name: lifeInsuranceBeneficiary.name,
            lastName: lifeInsuranceBeneficiary.lastName,
            email: lifeInsuranceBeneficiary.email,
            percentage: +lifeInsuranceBeneficiary.percentage,
            relationship: lifeInsuranceBeneficiary.relationship,
          };
        }
      );
    }

    return {
      ownerName: currentPayload.name,
      ownerLastName: currentPayload.lastName,
      identificationType:
        currentPayload.identificationType !== '' ? currentPayload.identificationType : null,
      identificationNumber:
        currentPayload.identificationNumber !== '' ? currentPayload.identificationNumber : null,
      mobilePhone: getPhoneFormat(currentPayload.phoneNumber),
      email: currentPayload.email,
      birthdate: currentPayload.birthday,
      originCountryId: currentPayload.nationality,
      originCountryName: countryName,
      cityId: currentPayload.city,
      communeId: currentPayload.commune,
      address: currentPayload.address,
      relationship: currentPayload.relationship,
      genderId: currentPayload.gender,
      gender: genderName,
      beneficiaries: beneficiaries,
      lifeInsuranceBeneficiaries: lifeInsuranceBeneficiaries,
      planId: isPayroll ? planId : null,
      url: isPayroll ? getUrl(url) : null,
      urlPlan: isPayroll ? infoToContract.infoPlan.urlPlan : null,
      genderName: isPayroll ? genderName : null,
      mobileBrand: currentPayload.mobileBrand,
      mobileModel: currentPayload.mobileModel,
      additionalField1: currentPayload.additionalField1,
      additionalField2: currentPayload.additionalField2,
      additionalField3: currentPayload.additionalField3,
      additionalField4: currentPayload.additionalField4,
    };
  };

  const getUrl = (currentUrl: string) => {
    return `https://${currentUrl}`;
  };

  const getBeneficiariesPayload = (currentPayload: any) => {
    return currentPayload.beneficiaries.map((beneficiary: any) => {
      let beneficiaryGenderName: string | null = null;
      let beneficiaryCountryName: string | null = null;

      if (infoToContract && infoToContract.genders) {
        const gender = infoToContract.genders.find((element) => element.id === beneficiary.gender);
        beneficiaryGenderName = gender ? gender.name : null;
      }

      if (infoToContract && infoToContract.countries) {
        const country = infoToContract.countries.find(
          (element) => element.id === beneficiary.nationality
        );
        beneficiaryCountryName = country ? country.name : null;
      }

      return {
        name: beneficiary.name,
        lastName: beneficiary.lastName,
        identificationType:
          beneficiary.identificationType !== '' ? beneficiary.identificationType : null,
        identificationNumber:
          beneficiary.identificationNumber !== '' ? beneficiary.identificationNumber : null,
        mobilePhone: getPhoneFormat(beneficiary.phoneNumber),
        email: beneficiary.email,
        birthdate: beneficiary.birthday,
        originCountryId: beneficiary.nationality,
        originCountryName: beneficiaryCountryName,
        cityId: beneficiary.city,
        communeId: beneficiary.commune,
        address: beneficiary.address,
        relationship: beneficiary.relationship,
        genderId: beneficiary.gender,
        gender: beneficiaryGenderName,
        mobileBrand: beneficiary.mobileBrand,
        mobileModel: beneficiary.mobileModel,
        additionalField1: beneficiary.additionalField1,
        additionalField2: beneficiary.additionalField2,
        additionalField3: beneficiary.additionalField3,
        additionalField4: beneficiary.additionalField4,
      };
    });
  };

  const getSubmit = (step: number) => {
    let realStep = step;
    const activeLifeInsurance = !!infoToContract?.infoPlan?.activeLifeInsurance;
    if (!activeLifeInsurance && step === 3) {
      realStep = 4;
    }

    switch (realStep) {
      case TITULAR_DATA_STEP:
        return handleSubmit(onSubmit, onError);
      case BENEFICIARY_DATA_STEP:
        if (!activeLifeInsurance && isMultiPayment)
          return handleSubmitBeneficiary(handleOpenDialogBeneficiaryList, onError);
        return handleSubmitBeneficiary(onSubmitBeneficiary, onError);
      case INSURANCE_LIFE_DATA_STEP:
        if (activeLifeInsurance && isMultiPayment)
          return handleInsuranceLifeBeneficiary(
            handleOpenDialogLifeInsuranceBeneficiaryList,
            onError
          );
        return handleInsuranceLifeBeneficiary(onSubmitInsuranceLifeBeneficiary, onError);
      case RESUME_AND_PAYMENT_STEP:
        return () => onSubmitResumeAndPaymet();
    }
  };

  const getDisabled = (step: number) => {
    let realStep = step;
    if (!infoToContract?.infoPlan?.activeLifeInsurance && step === 3) {
      realStep = 4;
    }

    switch (realStep) {
      case TITULAR_DATA_STEP:
        return false;
      case BENEFICIARY_DATA_STEP:
        return infoToContract
          ? watchBeneficiary('beneficiaries').length <
              infoToContract.infoPlan.numberMinimumBeneficiaries
          : false;
      case INSURANCE_LIFE_DATA_STEP:
        return percentageFill;
      case RESUME_AND_PAYMENT_STEP:
      case RESUME_AND_PAYMENT_STEP_2:
        return !confirmInfoPlan;
    }
  };

  const onChangeConfirmInfoPlan = () => {
    setConfirmInfoPlan(!confirmInfoPlan);
  };

  const onChangeAuthorizeRenewal = () => {
    setAuthorizeRenewal(!authorizeRenewal);
  };

  const getStepContent = (step: number) => {
    let realStep = step;
    if (!infoToContract?.infoPlan?.activeLifeInsurance && step === 3) {
      realStep = 4;
    }
    switch (realStep) {
      case TITULAR_DATA_STEP:
        return (
          infoToContract && (
            <TitularFormStep
              titularColumns={infoToContract.customerColumns.titularColumns}
              identificationTypesProp={infoToContract.identificationTypes}
              gendersProp={infoToContract.genders}
              countriesProp={infoToContract.countries}
              regionsProp={infoToContract.regions}
              control={control}
              alert={alert}
              watch={watch}
              setValue={setValue}
              getCommunesByRegionID={getCommunesByRegionID}
              businessUnitUUID={businesUnitUUID}
              validateRange={validateRange || false}
              validateRangeHeadline={validateRangeHeadline || false}
              validateRangeMin={validateRangeMin || 0}
              validateRangeMax={validateRangeMax || 0}
              errors={errorsHeadline}
            />
          )
        );

      case BENEFICIARY_DATA_STEP:
        return (
          infoToContract && (
            <BeneficiaryFormStep
              beneficiaryList={fields}
              beneficiaryAppend={append}
              beneficiaryRemove={remove}
              beneficiaryColumns={infoToContract.customerColumns.beneficiaryColumns}
              identificationTypesProp={infoToContract.identificationTypes}
              gendersProp={infoToContract.genders}
              countriesProp={infoToContract.countries}
              regionsProp={infoToContract.regions}
              minimumBeneficiaries={infoToContract.infoPlan.numberMinimumBeneficiaries}
              maximumBeneficiaries={infoToContract.infoPlan.numberMaximunBeneficiaries}
              control={controlBeneficiary}
              alert={alert}
              alertIdentification={alertIdentification}
              alertEmail={alertEmail}
              watch={watchBeneficiary}
              watchTitular={watch}
              setValue={setValueBeneficiary}
              getCommunesByRegionID={getCommunesByRegionID}
              identificationChange={identificationChange}
              isIdentificationType={isIdentificationType}
              triggerBeneficiary={triggerBeneficiary}
              businessUnitUUID={businesUnitUUID}
              validateRange={validateRange || false}
              validateRangeBeneficiary={validateRangeBeneficiary || false}
              validateRangeMin={validateRangeMin || 0}
              validateRangeMax={validateRangeMax || 0}
              errors={errorsBeneficiary}
            />
          )
        );
      case INSURANCE_LIFE_DATA_STEP:
        return (
          infoToContract && (
            <InsuranceLifeFormStep
              lifeInsuranceBeneficiaryList={fieldsInsuranceLifeBeneficiary}
              lifeInsuranceBeneficiaryAppend={appendInsuranceLifeBeneficiary}
              lifeInsuranceBeneficiaryRemove={removeInsuranceLifeBeneficiary}
              maximumBeneficiariesLifeInsurance={
                infoToContract.infoPlan.numberMaximumBeneficiariesLifeInsurance
              }
              control={controlInsuranceLifeBeneficiary}
              alert={alert}
              watch={watchInsuranceLifeBeneficiary}
              setValue={setValueInsuranceLifeBeneficiary}
              percentageChange={percentageChange}
              showAlert={showPercentageAlert && percentageFill}
            />
          )
        );

      case RESUME_AND_PAYMENT_STEP:
      case RESUME_AND_PAYMENT_STEP_2:
        return (
          infoToContract && (
            <ResumeAndPaymentStep
              name={infoToContract.infoPlan.name}
              description={infoToContract.infoPlan.description}
              paymentTypeId={infoToContract.infoPlan.paymentTypeId}
              paymentTypeName={infoToContract.infoPlan.paymentTypeName}
              duration={infoToContract.infoPlan.paymentDurationMonths}
              frequency={infoToContract.infoPlan.paymentFrequencyName}
              price={infoToContract.infoPlan.price}
              confirmInfoPlan={confirmInfoPlan}
              authorizeRenewal={authorizeRenewal}
              payrollEndWizard={payrollEndWizard}
              notPayrollEndWizard={notPayrollEndWizard === 2}
              onChangeConfirmInfoPlan={onChangeConfirmInfoPlan}
              onChangeAuthorizeRenewal={onChangeAuthorizeRenewal}
              handleDownload={handleDownload}
              paymentsFrequency={
                infoToContract?.infoPlan.paymentsFrequencies
                  ? infoToContract?.infoPlan.paymentsFrequencies[paymentId]
                  : null
              }
            />
          )
        );
    }
  };

  const getCardActions = () => {
    if (payrollEndWizard) {
      return (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'end',
            padding: '1.25rem 1rem 0rem',
            justifyContent: 'center',
            alignSelf: 'start',
          }}
        >
          <CardActions className={classes.formActions}>
            <Button
              variant="contained"
              onClick={handleCancel}
              sx={{ mr: 1 }}
              className={classes.buttonHome}
            >
              {t('pages.hirePlan.goHome.button')}
            </Button>
          </CardActions>
        </Grid>
      );
    }

    if (notPayrollEndWizard !== 0 && showLinkButton) {
      return (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'end',
            padding: '1.25rem 1rem 0rem',
            justifyContent: 'center',
            alignSelf: 'start',
          }}
        >
          <CardActions className={classes.formActions}>
            <Button
              variant="contained"
              onClick={handleGoLink}
              sx={{ mr: 1 }}
              className={
                dataPrivacyTerms != null
                  ? getDisabled(activeStep + 1)
                    ? classes.buttonDisabled
                    : classes.buttonHome
                  : classes.buttonHome
              }
              disabled={dataPrivacyTerms != null ? getDisabled(activeStep + 1) : false}
            >
              {t('pages.hirePlan.goLink.button')}
            </Button>
            {notPayrollEndWizard === 2 && (
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{ mr: 1 }}
                className={classes.buttonCancel}
              >
                {t('pages.hirePlan.goHome.button')}
              </Button>
            )}
          </CardActions>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'end', padding: '1.25rem 1rem 0rem' }}>
        <CardActions className={classes.formActions}>
          {activeStep !== 0 && (
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              startIcon={<ArrowBackIcon />}
              className={classes.buttonNavigation}
            >
              {t('pages.hirePlan.button.back')}
            </Button>
          )}
          {activeStep === 0 && (
            <Button onClick={handleCancel} sx={{ mr: 1 }} className={classes.buttonCancel}>
              {t('pages.hirePlan.button.cancel')}
            </Button>
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            type="submit"
            onClick={getSubmit(activeStep + 1)}
            endIcon={<ArrowForwardIcon />}
            className={getDisabled(activeStep + 1) ? classes.default : classes.buttonNavigation}
            disabled={getDisabled(activeStep + 1)}
          >
            {activeStep === getStepsToShow().length - 1
              ? t('pages.hirePlan.button.end')
              : t('pages.hirePlan.button.next')}
          </Button>
        </CardActions>
      </Grid>
    );
  };

  if (errorScreen) {
    return (
      <ContainerPage>
        <ErrorScreen title={errorTitle} body={errorBody} handleBack={handleCancel} />
      </ContainerPage>
    );
  }

  const isMultiPayment =
    infoToContract?.infoPlan &&
    !infoToContract?.infoPlan.price &&
    infoToContract?.infoPlan.paymentsFrequencies.length > 1;

  return (
    <ContainerPage>
      <MultiPaymentModal
        options={
          infoToContract?.infoPlan.paymentsFrequencies
            ? infoToContract.infoPlan.paymentsFrequencies
            : []
        }
        isOpenDialog={multiPaymentModal}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={onSubmitMultiPayment}
        setPaymentId={setPaymentId}
      />
      <CardPlanDetailsDialog />
      <CustomBreadcumbs />
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={smallSize ? 12 : 8}>
            <HorizontalLinearStepper steps={getStepsToShow()} activeStep={activeStep} />
          </Grid>
          <Grid item xs={smallSize ? 12 : 1} />
          {activeStep < getStepsToShow().length - 1 && (
            <CardHirePlanMobileWithArcheType infoToContract={infoToContract} />
          )}
          <Grid item xs={smallSize ? 12 : activeStep < getStepsToShow().length - 1 ? 8 : 12}>
            <Card className={classes.formCard}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={
                    payrollEndWizard || notPayrollEndWizard ? classes.containerEnd : classes.default
                  }
                >
                  <CardContent className={classes.cardContent}>
                    <form autoComplete="off">{getStepContent(activeStep + 1)}</form>
                  </CardContent>
                </Grid>
                {getCardActions()}
              </Grid>
            </Card>
          </Grid>
          {activeStep < getStepsToShow().length - 1 && (
            <CardHirePlanWithArcheType infoToContract={infoToContract} />
          )}
        </Grid>
      </Box>
    </ContainerPage>
  );
};

export default HirePlan;
